import React, { useEffect } from "react";
import axios from "axios";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import ReactLoading from "react-loading";
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage, FileCopy } from "@material-ui/icons";
import IconButton from '@material-ui/core/IconButton';
import { TableContainer, TablePagination, Table, TableBody, TableCell, TableFooter, TableRow, TableHead, Button, Tooltip, Typography } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import TopStatus from "components/Header/TopStatus.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";

import styles from "assets/jss/material-kit-react/views/home.js";
import { Link } from "react-router-dom";
import { dateFormat } from "../helper/dateFormat";

// Slick Carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import { connection } from "../helper/connection";
import { ContractToSolid } from "../helper/ContractToSolid";

import x3matrix from '../JSON/x3matrix.json';
import x6matrix from '../JSON/x6matrix.json';
import config from '../config/config';
import { currencyRate } from "../helper/currencyRate";


const dashboardRoutes = [];
const baseUrl = config.baseUrl;
const useStyles = makeStyles(styles);

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function createData(id, date, hash, bnb, usd) {
  return { id, date, hash, bnb, usd };
}

const rows = [
  createData('339832', "30-08-2020  03:15", "TKd98we9VsAzxEXFWm12ssEw54Hd98", "1000", "$75"),
  createData('339832', "30-08-2020  03:15", "TKd98we9VsAzxEXFWm12ssEw54Hd98", "1000", "$75"),
  createData('339832', "30-08-2020  03:15", "TKd98we9VsAzxEXFWm12ssEw54Hd98", "1000", "$75"),
  createData('339832', "30-08-2020  03:15", "TKd98we9VsAzxEXFWm12ssEw54Hd98", "1000", "$75"),
  createData('339832', "30-08-2020  03:15", "TKd98we9VsAzxEXFWm12ssEw54Hd98", "1000", "$75"),
].sort((a, b) => (a.id > b.id ? -1 : 1));

export default function DashboardDetails(props) {

  const classes = useStyles();
  const { ...rest } = props;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [x3MatrixList, setx3MatrixList] = React.useState(x3matrix);
  const [x3status, setx3status] = React.useState(false);
  const [x6MatrixList, setx6MatrixList] = React.useState(x6matrix);
  const [x6status, setx6status] = React.useState(false);
  const [matrixList, setmatrixList] = React.useState([]);
  const [matrixstatus, setmatrixstatus] = React.useState(false);
  const [rate, setrate] = React.useState(0);
  const [userid, setuserid] = React.useState(0);
  const [matrixtxt, setmatrixtxt] = React.useState("");
  const [transactionList, settransactionList] = React.useState([]);
  const [showload, setshowload] = React.useState(true);
  const [matrixType, setmatrixType] = React.useState("");

  useEffect(() => {

    var hash = window.location.hash.substr(1);
    setTimeout(function () {

      if (hash === 'matrix3') {
        updatex3Level();
        setmatrixtxt("Silver");
        getTransaction(hash);
        setmatrixType(hash);
      }
      if (hash === 'matrix6') {
        updatex6Level();
        setmatrixtxt("Gold");
        getTransaction(hash);
        setmatrixType(hash);
      }
    }, 1500);

  }, []);

  async function updatex3Level() {

    try {
      var WEB3 = await connection();
      var currAddr = WEB3.address;

      var getVal = await currencyRate();
      setrate(getVal);

      var response = await axios.post(baseUrl + 'user-matrix-details', { address: currAddr });
      var result = (response && response.data && response.data.result) ? response.data.result : [];

      var Contract = await ContractToSolid();

      var userData = await Contract.methods.users(currAddr).call();
      var id = (userData && userData.id) ? userData.id : 0
      setuserid(id)

      var currentMatrix = userData.x3Level;
      var x;
      var cnt = 0;
      var amt = 0;
      for (x in x3MatrixList) {
        cnt++;
        if (cnt <= currentMatrix) {

          var get3XMatrix = await Contract.methods.usersX3Matrix(currAddr, cnt).call();

          if (result.length > 0) {
            var index = result.findIndex(val => val.level == x3MatrixList[x].level);
            if (index != -1) {
              amt = result[index].silver;
            }
          }

          var rebirthcnt = get3XMatrix[3];
          var currentLength = get3XMatrix[1].length;
          var total = (rebirthcnt * 3) + currentLength;
          x3MatrixList[x].rebirth = rebirthcnt;
          x3MatrixList[x].total_user = total;
          x3MatrixList[x].amount = amt;
        }

      }
      setmatrixstatus(true);
      setx3MatrixList(x3MatrixList);
      setmatrixList(x3MatrixList)
    } catch (err) {

    }

  }

  async function updatex6Level() {

    try {
      var WEB3 = await connection();
      var currAddr = WEB3.address;

      var getVal = await currencyRate();
      setrate(getVal);

      var response = await axios.post(baseUrl + 'user-matrix-details', { address: currAddr });
      var result = (response && response.data && response.data.result) ? response.data.result : [];

      var Contract = await ContractToSolid();
      var userData = await Contract.methods.users(currAddr).call();
      var id = (userData && userData.id) ? userData.id : 0
      setuserid(id);

      var currentMatrix = userData.x6Level;
      var x;
      var cnt = 0;
     
      for (x in x6MatrixList) {
        var amt = 0;
        cnt++;
        if (cnt <= currentMatrix) {
          var get6XMatrix = await Contract.methods.usersX6Matrix(currAddr, cnt).call();

          if (result.length > 0) {

            var index = result.findIndex(val => val.level.toString() == x6MatrixList[x].level.toString());

            if (index != -1) {
              amt = result[index].gold;
            }
          }
          console.log('amtamtamtamt', amt)
          var rebirthcnt = get6XMatrix[5];
          var firstLevelReferrals = get6XMatrix[1].length;
          var secondLevelReferrals = get6XMatrix[2].length;

          var totalReferal = parseFloat(firstLevelReferrals) + parseFloat(secondLevelReferrals);

          var total = (rebirthcnt * 6) + totalReferal;
          x6MatrixList[x].rebirth = rebirthcnt;
          x6MatrixList[x].total_user = total;
          x3MatrixList[x].amount = amt;
        }

      }
      setmatrixstatus(true);
      setx6MatrixList(x6MatrixList);
      setmatrixList(x3MatrixList)
    } catch (err) {

    }

  }

  async function getTransaction(type) {
    var WEB3 = await connection();
    var currAddr = WEB3.address;
    var response = await axios.post(baseUrl + 'get-matrix-transaction', { type: type, address: currAddr });
    if (response && response.data && response.data.result && response.data.result.length > 0) {
      var list = response.data.result;
      settransactionList(list);
    }

  }

  async function loadMore() {
    var WEB3 = await connection();
    var currAddr = WEB3.address;
    var response = await axios.post(baseUrl + 'get-matrix-transaction', { type: matrixType, page: page, address: currAddr });
    if (response && response.data && response.data.result && response.data.result.length > 0) {
      var list = response.data.result;
      var skip = response.data.page;
      var totalList = transactionList.concat(list);
      setPage(skip);
      settransactionList(totalList);
    } else {
      setshowload(false)
    }

  }


  var buy_item_carousel = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          arrows: false
        }
      }
    ]
  };

  return (
    <div className="inner_pages_panel">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" />}
        rightLinks={<HeaderLinksAfterlogin />}
        changeColorOnScroll={{
          height: 100,
          color: "dark"
        }}
        {...rest}
      />
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={3}>
              <Sidebar />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={9}>
              <div className="dashboard_overall">
                <div className={classes.container + " px-0"}>
                  <div className="dashTitleBox">
                    <h2 className="DashboardTitle">User Details</h2>
                    <TopStatus />
                  </div>
                </div>
              </div>
              <div className="chance_panel">
                {matrixtxt != "" && <h2>Tx<span>{matrixtxt}</span></h2>}
                <div className="buy_item_carousel">
                  <Slider {...buy_item_carousel}>

                    {matrixstatus && matrixList && matrixList.length > 0 && matrixList.map((row, index) => {
                      return (
                        <div>
                          <div className="buy_data_item_panel">
                            <div className="buy_data_item mr-2">
                              <div className="buy_data_count buy_data_count_fontlarge"><p>{row.rebirth}</p></div>
                              <div className="buy_data_icon"><img src={require("../assets/images/buy_icon_02.png")} alt="Icon" /></div>
                            </div>
                            <div className="buy_item">
                              <div className="buy_item_inner_top">
                                <div class="input-group">
                                  <input type="text" class="form-control" />
                                  <div class="input-group-append">
                                    <span class="input-group-text">
                                      <img src={require("../assets/images/uparrow_icon.png")} alt="Icon" />
                                      <span className="sliderID">ID {userid}</span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="buy_item_inner">
                                <div className="buy_count">
                                  <h6>ID: {userid}</h6>
                                  <p className="mb-0">{row.amount} BUSD</p>
                                  <h6>${(row.amount > 0) ? (parseFloat(row.amount) * parseFloat(rate).toFixed(4)) : "0"}</h6>
                                </div>
                                <div className="buy_dots_row">
                                  <a href="#" className="buy_dot">
                                    <span className="csNumber">Level {row.level}</span>
                                  </a>
                                </div>


                              </div>
                            </div>
                            <div className="buy_item_carousel_right">
                              <div className="buy_data_item">
                                <div className="buy_data_count buy_data_count_fontlarge"><p>{row.total_user}</p></div>
                                <div className="buy_data_icon"><img src={require("../assets/images/buy_icon_01.png")} alt="Icon" /></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </Slider>
                  <div style={{ "padding-left": "178px" }}>
                    {!matrixstatus && <ReactLoading type={"spinningBubbles"} color="#b8873a" className="loading" />}</div>
                </div>
              </div>
              <div className="dash_panel">
                <div className="table-responsive">
                  <TableContainer>
                    <Table className={classes.table + " wallet_history_table"} aria-label="custom pagination table">
                      <TableHead>
                        <TableRow>
                          <TableCell>ID</TableCell>
                          <TableCell>Date</TableCell>
                          <TableCell>Hash</TableCell>
                          <TableCell>BUSD</TableCell>
                          <TableCell>USD</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(transactionList
                        ).map((row) => (
                          <TableRow key={row.name}>
                            <TableCell align="left">
                              {row.fromid}
                            </TableCell>
                            <TableCell scope="row">
                              {dateFormat(row.createdAt,"YYYY-MM-DD HH:mm")}
                            </TableCell>
                            <TableCell align="left">
                              <span className="table_trans_id">{row.txid}</span>
                            </TableCell>
                            <TableCell align="left">
                              {row.price}
                            </TableCell>
                            <TableCell align="left">
                              {row.price}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {showload &&transactionList && transactionList.length >0 &&
                      <div className="text-center">
                        <Button className="auth_btn1" onClick={() => { loadMore() }}>Load More</Button>
                      </div>
                    }
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
