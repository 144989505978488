// Generate a config at https://vincentgarreau.com/particles.js/

export default {
  "particles": {
    
    "number": {
        "value": 100,
        "density": {
            "enable": true,
            "value_area": 800
        }
    },
    "line_linked": {
        "enable": false
    },
    "move": {
        "speed": 3,
        "out_mode": "out"
    },    
    "shape": {
        "type": [
            "image",
            "circle"
        ],        
        "image": [
            {
                "src": "src/assets/images/tron.png",
                "height": 100,
                "width": 100
            },
            {
                "src": "src/assets/images/tron.png",
                "height": 100,
                "width": 100
            },
            {
                "src": "src/assets/images/tron.png",
                "height": 100,
                "width": 100
            
            }
        ]
    },
    "color": {
        "value": "#fff"
    },
    "size": {
        "value": 3,
        "random": false,
        "anim": {
            "enable": true,
            "speed": 4,
            "size_min": 3,
            "sync": false
        }
    }
},
"retina_detect": false

};
