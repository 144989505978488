import React, { useEffect } from "react";

// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PropTypes from 'prop-types';

import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage, FileCopy } from "@material-ui/icons";
import IconButton from '@material-ui/core/IconButton';
import { TableContainer, TablePagination, Table, TableBody, TableCell, TableFooter, TableRow, TableHead, Button, Tooltip, Typography } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import TopStatus from "components/Header/TopStatus.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";
import { dateFormat } from "../helper/dateFormat";

import styles from "assets/jss/material-kit-react/views/home.js";
import { Link } from "react-router-dom";

import config from "../config/config";
import { connection } from "../helper/connection";
import axios from "axios";

// Slick Carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";


const dashboardRoutes = [];
const baseUrl = config.baseUrl;

const useStyles = makeStyles(styles);

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function createData(id, program, level, bnb, hash, date) {
  return { id, program, level, bnb, hash, date };
}

const rows = [
  createData( '339832', "Silver", "2", "875", "TKd98we9VsAzxEXFWm12ssEw54Hd98", "30-08-2020  03:15" ),
  createData( '539832', "Gold", "1", "5896", "TKd98we9VsAzxEXFWm12ssEw54Hd98", "30-08-2020  03:15" ),
  createData( '9832', "Silver", "4", "25689", "TKd98we9VsAzxEXFWm12ssEw54Hd98", "30-08-2020  03:15" ),
  createData( '832', "Platinum", "6", "542", "TKd98we9VsAzxEXFWm12ssEw54Hd98", "30-08-2020  03:15" ),
  createData( '61832', "Gold", "8", "245", "TKd98we9VsAzxEXFWm12ssEw54Hd98", "30-08-2020  03:15" ),
].sort((a, b) => (a.id > b.id ? -1 : 1));

export default function Statistics(props) {

  const classes = useStyles();
  const { ...rest } = props;

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [StatisticsList, setStatisticsList] = React.useState([]);
  const [showload, setshowload] = React.useState(true);
  const [page1, setPage1] = React.useState(1);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  var buy_item_carousel = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          arrows: false
        }
      }
    ]
  };

  useEffect(() => {

    setTimeout(function(){
      getStatistics();
    },400);
   
  }, []);

  async function getStatistics(){

    var WEB3 = await connection();
    if (WEB3.isMeta) {
      
      var currAddr = WEB3.address;
      var response = await axios.get(baseUrl + 'statistic-history', {});
      if(response && response.data && response.data.result && response.data.result.length>0){
        var list = response.data.result;
        setStatisticsList(list);
      }
    }

  }

  async function loadMore(){

    var response = await axios.get(baseUrl + 'statistic-history?page='+page1);
    if(response && response.data && response.data.result && response.data.result.length>0){
      var list = response.data.result;
      var skip = response.data.page;
      var totalList = StatisticsList.concat(list);
      setPage1(skip);
      setStatisticsList(totalList);
    }else{
      setshowload(false)
    }

  }
  
  return (
    <div className="inner_pages_panel">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid"/>}
        rightLinks={<HeaderLinksAfterlogin />}
        changeColorOnScroll={{
          height: 100,
          color: "dark"
        }}
        {...rest}
      />
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={3}>
              <Sidebar />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={9}>
              <div className="dashboard_overall">
                <div className={classes.container + " px-0"}>
                  <div className="dashTitleBox">
                    <h2 className="DashboardTitle">Statistics</h2>
                    <TopStatus />
                  </div>
                </div>
              </div>
              <div className="dash_panel">
                <h2 className="dash_panel_Title">Financial Statistics</h2>
                <div className="table-responsive">
                  <TableContainer>
                      <Table className={classes.table + " wallet_history_table"} aria-label="custom pagination table">
                        <TableHead>
                          <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Program</TableCell>
                            <TableCell>Level</TableCell>
                            <TableCell>BUSD</TableCell>
                            <TableCell>Hash</TableCell>
                            <TableCell>Date</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {StatisticsList.map((row) => (
                            <TableRow key={row.name}>
                              <TableCell align="left">
                                {row.fromid}
                              </TableCell>
                              <TableCell align="left">
                                {(row.matrix==1)?"Silver":"Gold"}
                              </TableCell>
                              <TableCell align="left">
                                {row.level}
                              </TableCell>
                              <TableCell align="left">
                                {row.price}
                              </TableCell>
                              <TableCell align="left">
                                <span className="table_trans_id">{row.txid}</span>
                              </TableCell>
                              <TableCell scope="row">
                                {dateFormat(row.createdAt,"YYYY-MM-DD HH:mm")}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        
                      </Table>
                      {showload &&
                          <div className="text-center mt-3">
                            <Button className="auth_btn1" onClick={() =>{ loadMore()}}>Load More</Button>
                          </div>
                        }
                    </TableContainer>
                  </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
