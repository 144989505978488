import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import HeaderLinks from "components/Header/HeaderLinks.js";
import FooterHome from "components/Footer/FooterHome.js";
import styled from "styled-components";

import styles from "assets/jss/material-kit-react/views/home.js";
import { Link } from "react-router-dom";

// Particles
import Particles from 'react-particles-js';
import particlesConfig from 'components/particles-config.js';


const Icon = styled(props => (
  <div {...props}>
    <div className="minus">x</div>
    <div className="plus">+</div>
  </div>
))`
  & > .plus {
    color: #010017;
    font-size: 40px;
    background-color: #0062bb;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & > .minus {
    display: none;
    color: #010017;
    font-size: 20px;
  }
  .Mui-expanded & > .minus {
    color: #010017;
    font-size: 20px;
    background-color: #0062bb;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 25px;
    font-weight: 700;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function FAQ(props) {
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState('panel1');

  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  const { ...rest } = props;

  return (
    <div style={{
      width: "100%",
      height: "100%",
      margin: "0",
      padding: "0"
    }}>
      <Particles params={particlesConfig}
        style={{
          position: 'fixed',
          zIndex: 1,
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
          width: "100%",
          height: "100%"
      }}/>
      <div className="inner_pages_panel">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid"/>}
        // rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 100,
          color: "dark"
        }}
        {...rest}
      />
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} className="text-center m-auto">
              <h2 className="inner_title text-center">FAQ</h2>
              <div className="faq_setion_panel mt-5">
                <Accordion expanded={expanded === 'panel1'} onChange={handleChangeFaq('panel1')}>
                  <AccordionSummary expandIcon={<Icon />} aria-controls="panel1bh-content" id="panel1bh-header">
                    <h2 className="accordian_head">What should I include in my personal statement?</h2>
                    <span className="accordian_head_count">1.</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p className="accordian_para">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure enderit. Fusce ut velit laoreet, tempus arcu eu, molestie tortor. Nam vel justo cursus, faucibus lorem eget, egestas eros.</p>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel2'} onChange={handleChangeFaq('panel2')}>
                  <AccordionSummary expandIcon={<Icon />} aria-controls="panel2bh-content" id="panel2bh-header">
                    <h2 className="accordian_head">Will membership plans be charged automatically? </h2>
                    <span className="accordian_head_count">2.</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p className="accordian_para">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure enderit. Fusce ut velit laoreet, tempus arcu eu, molestie tortor. Nam vel justo cursus, faucibus lorem eget, egestas eros.</p>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel3'} onChange={handleChangeFaq('panel3')}>
                  <AccordionSummary expandIcon={<Icon />} aria-controls="panel3bh-content" id="panel3bh-header">
                    <h2 className="accordian_head">How can I make a change to my application? </h2>
                    <span className="accordian_head_count">3.</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p className="accordian_para">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure enderit. Fusce ut velit laoreet, tempus arcu eu, molestie tortor. Nam vel justo cursus, faucibus lorem eget, egestas eros.</p>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel4'} onChange={handleChangeFaq('panel4')}>
                  <AccordionSummary expandIcon={<Icon />} aria-controls="panel4bh-content" id="panel4bh-header">
                    <h2 className="accordian_head">Where can I find out about funding?</h2>
                    <span className="accordian_head_count">4.</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p className="accordian_para">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure enderit. Fusce ut velit laoreet, tempus arcu eu, molestie tortor. Nam vel justo cursus, faucibus lorem eget, egestas eros.</p>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel5'} onChange={handleChangeFaq('panel5')}>
                  <AccordionSummary expandIcon={<Icon />} aria-controls="panel5bh-content" id="panel5bh-header">
                    <h2 className="accordian_head">Can I get a free trial before I purchase? </h2>
                    <span className="accordian_head_count">5.</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p className="accordian_para">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure enderit. Fusce ut velit laoreet, tempus arcu eu, molestie tortor. Nam vel justo cursus, faucibus lorem eget, egestas eros.</p>
                  </AccordionDetails>
                </Accordion>
              </div>
            </GridItem>
          </GridContainer>
         
        </div>
      </div>
      <FooterHome/>
      </div>
    </div>
  );
}
