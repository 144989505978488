import React from "react";
import ReactDOM from "react-dom";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import './index.css';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

// pages for this product
import Home from "views/home.js";
import Login from "views/login.js";
import Register from "views/register.js";
import Forgot from "views/forgot.js";
import ChangePassword from "views/change-password.js";
import Dashboard from "views/dashboard.js";
import DashboardDetails from "views/dashboard-details.js";
import Statistics from "views/Statistics.js";
import Partners from "views/Partners.js";
import FAQ from "views/faq.js";
import Discaimers from './views/disclaimers'

import PrivateRoute from "./private-route/PrivateRoute";

ReactDOM.render(
  <BrowserRouter basename={"/"}>
    <ToastContainer/>
    <Switch>
      <Route path="/faq" component={FAQ} />
      <PrivateRoute path="/statistics" component={Statistics} />
      <Route path="/disclaimers" component={Discaimers} />
      <PrivateRoute path="/partners" component={Partners} />
      <PrivateRoute path="/user-details" component={DashboardDetails} />
      <PrivateRoute path="/dashboard" component={Dashboard} />
      <Route path="/change-password" component={ChangePassword} />
      <Route path="/forgot-password" component={Forgot} />
      <Route path="/register" component={Register} />
      <Route path="/login" component={Login} />
      <Route path="/" component={Home} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
