import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "components/Header/Header.js";
import FooterHome from "components/Footer/FooterHome.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";
import styled from "styled-components";

import styles from "assets/jss/material-kit-react/views/home.js";
import { Link } from "react-router-dom";
import { Button, Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";


// Slick Carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Icon = styled(props => (
  <div {...props}>
    <div className="minus">x</div>
    <div className="plus">+</div>
  </div>
))`
  & > .plus {
    color: #010017;
    font-size: 40px;
    background-color: #0062bb;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & > .minus {
    display: none;
    color: #010017;
    font-size: 20px;
  }
  .Mui-expanded & > .minus {
    color: #010017;
    font-size: 20px;
    background-color: #0062bb;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 25px;
    font-weight: 700;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState('panel1');

  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const { ...rest } = props;

  return (
    <div className="bg-white-disclaimers">
      <Header
        fixed
        color="#002341"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid"/>}
        rightLinks={(localStorage.getItem('nhfgytuvc')=="yes")?<HeaderLinksAfterlogin />:<HeaderLinks />}
        changeColorOnScroll={{
          height: 100,
          color: "dark"
        }}
        {...rest}
      />
        <main className="container">
          <section className="disclaimers">
            <h3>Disclaimer</h3>
            <div className="disclimer_first"> 
              <p> 
              You expressly understand that tosolid.vip website is only the interface to interact with 
              smart contracts on the blockchain and agree that your use of TOSOLID website(s) is at 
              your  sole  risk  and  that  the  services  are  provided  "As  is"  and  "As  available"  without 
              warranty of any kind, either expressed or implied, that is not expressly stated in the 
              official documentation.
              Additionally, no advice or information, whether oral or written, obtained by you from 
              members  of  the  TOSOLID  community  shall  create  any  warranty  that  is  not  expressly 
              stated in the official documentation. 
            </p>
            <p>In  no  event  shall  TOSOLID  be  held  liable  to  you  or  any  third  party  for  any  damages 
including, but not limited to, indirect, special, incidental, or consequential damages or 
other losses (including damages for loss of business, loss of profits, lost savings, business 
interruption  or  the  like),  arising  from  the  use  of  website(s),  customer  products  or 
external links and affiliate websites. </p>
        <p>You, your partners and any third parties, agree to indemnify and hold harmless TOSOLID 
from any damages claimed because of services, products, information, and resources 
obtained from TOSOLID and/or in any event including, but not limited to, spam, account 
hacking, identity theft, virus attack, fraudulent or criminal activities (including real, 
perceived, probable or suspected fraud/crime). </p>
<p>TOSOLID  shall  not  be held  liable  based  on any  theory  of liability  including  breach  of 
contract, breach of warranty, tort (including negligence), product liability or otherwise, 
even if members of the TOSOLID community or its representatives have been advised 
of the possibility of such damages and even if a remedy set forth by TOSOLID is found 
to have failed of its essential purpose.</p>
<h4>A description of the website's core features and functionality </h4>
<p>This  website  provides  a  free  service  for  interacting  with  ToSolid  Community  smart 
contracts. The website only provides information. The website does not collect or save 
money  and  funds.  The  website  is  not  a  ToSolid  operational  centre,  nor  does  it  run 
ToSolid Community smart contracts. </p>
<p>This website explains how smart contracts work, their algorithms, and communication 
rules among participants. In addition, the website provides assistance in working with 
smart contracts by making smart contracts understandable and interactive.</p>
<p>Anyone can interact directly with ToSolid's smart contracts without using the website.</p>
<p>No one controls or manages the algorithm of the smart contract. With the help of the 
smart contract, all transactions involving funds are p2p transactions. The funds are not 
collected by anyone.</p>
<h4>The website's accessibility </h4>
<p>For guidance and assistance in interacting with smart contracts, the ToSolid Community 
will use its best efforts to keep this website available at all times. However, we will 
not be responsible if the website is unavailable for any period of time.Users may be denied access to the website at any time without prior written notice. </p>
<h4>Updates</h4>
<p>The  ToSolid  Community  has  the  right  to  update  and  revise  the  website's  terms  and 
conditions at any time. As a result, all users are covered by such updates.</p>
<h4>Liability</h4>
<p>As a result of accessing or using the website, the ToSolid Community will not be liable 
for any losses resulting from its contents, information, or actions or decisions taken as 
a result of such access. </p>
<p>For all its efforts to ensure accuracy and relevance, ToSolid Community cannot be held 
responsible  for  the  actions  taken  by  individuals  or  organizations  on  the  basis  of 
information found on or obtained from the website.</p>
<p>It's provided "as is" without express or implied warranties. ToSolid Community makes no 
assurances or warranties about the website or its contents, including, but not limited 
to, the accuracy of the information, regarding the website and its contents' timeliness, 
relevance,  completeness,  fullness,  accuracy,  availability,  or  fitness  for  a  particular 
purpose,  it  is  safe  and  uninterruptible  and  that  there  will  be  no  omissions  in  its 
operation, any errors will be corrected by the ToSolid Community, and the website will 
be  free  of  viruses  and  other  malware,  whether  or  not  the  website  and  its  content 
infringe on the rights of others.</p>
<p>The  ToSolid  Community  disclaims  any  implied  assurances  and  guarantees,  and  is  not 
liable for the website, its content, or its use.</p>
<p>The information and materials on this website represent the opinions and expertise of 
the ToSolid community, but do not constitute financial or legal advice.</p>
<p>The user is solely responsible for accessing the website and using its content.</p>
<h4>As  a  result  of  your  use  of  this  website,  you  agree  to  the  terms  and 
conditions outlined in this Legal Disclaimer.</h4>

            </div>
          </section>
          </main>     
      <FooterHome />
    </div>
  );
}
