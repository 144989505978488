import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import { Link } from "react-router-dom";
import { Button } from '@material-ui/core';

const useStyles = makeStyles(styles);
const dashboardRoutes = [];
export default function ChangePassword(props) {
  
  const { ...rest } = props;
  const classes = useStyles();
  return (
    <div>
         <div className="auth_panel auth_panel_reg">
        <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid"/>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 100,
          color: "dark"
        }}
        {...rest}
      />
      <div className={classes.container + " pb-5"}>
        <GridContainer justify="center">
          <GridItem sm={12} md={8} lg={6} className="m-auto">
            <form className="auth_form">
              <div className="authImgTop">
                  <img src={require("../assets/images/lockIcon.png")} alt="" className="img-fluid"/>
                </div>
              <h3>Change Password</h3>
              <div className="form-row">
                <div className="form-group col-md-12">
                  <label htmlFor="oldpassword" className="auth_label text-left">Old Password</label>
                  <input type="password" className="auth_input" id="oldpassword" placeholder="Enter Old Password"/>
                </div>
                <div className="form-group col-md-12">
                  <label htmlFor="newpassword" className="auth_label text-left">New Password</label>
                  <input type="password" className="auth_input" id="newpassword" placeholder="Enter New Password"/>
                </div>
                <div className="form-group col-md-12">
                  <label htmlFor="cnewpassword" className="auth_label text-left">Confirm New Password</label>
                  <input type="password" className="auth_input" id="cnewpassword" placeholder="Enter New Password"/>
                </div>
              </div>
              <div className="text-center">
                <Button className="auth_btn mt-3">Submit</Button>
              </div>
            </form>
          </GridItem>
        </GridContainer>
      </div>
      </div>
    </div>
  );
}
